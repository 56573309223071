import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'

const app = createApp(App);
app.use(router); // 使用 router
app.use(ElementPlus); // 使用 ElementPlus
app.mount('#app');
